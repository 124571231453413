import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import AcademyThankYouHero from '../sections/academy/AcademyThankYouHero';

const AcademyThankYouPage = () => {
  const { academy } = useStaticQuery(graphql`
    {
      academy: allFile(filter: { relativeDirectory: { eq: "images/academy" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const academyImages = {};
  academy.nodes.forEach(img => {
    academyImages[img.name] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='Nexton Academy Thank You'
        description='Thank you for applying to Nexton Academy. Your journey starts here!'
        image='https://www.nextonlabs.com/academy-banner.png'
      />
      <Layout selected='academy-thank-you'>
        <Box className='full-width' bg='nextonLightGrey' minHeight='calc(100vh - 100px)'>
          <AcademyThankYouHero academyImages={academyImages} />
        </Box>
      </Layout>
    </>
  );
};

export default AcademyThankYouPage;
