import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const BlueBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonBlue};
  position: absolute;
  top: 40px;
  right: 39%;
  width: 71px;
  height: 71px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    top: 31%;
    display: none;
  }
`;

const YellowBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonYellow};
  position: absolute;
  top: 200px;
  right: -1%;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    top: 490px;
    right: -4%;
  }
`;

const LightOrangeBubble = styled(Box)`
  background-color: #ffefe4;
  position: absolute;
  top: 490px;
  right: 38%;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    top: 650px;
    right: 60%;
  }
`;

const FormBubbles = () => (
  <Box>
    <BlueBubble />
    <YellowBubble />
    <LightOrangeBubble />
  </Box>
);

export default FormBubbles;
