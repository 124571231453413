import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import FormBubbles from '../../components/academy/FormBubbles';

const AcademyThankYouHero = ({ academyImages }) => (
  <Box className='body-layout'>
    <Flex flexWrap='wrap' py='75px' style={{ position: 'relative', zIndex: '0' }}>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mt={['0px', '0px', '90px']}>
        <GatsbyImage image={academyImages.logo} alt='Nexton Academy Logo' />
        <Text
          as='h1'
          fontWeight='400'
          color='nextonOceanBlue'
          fontSize={['50px', '70px']}
          lineHeight='1'
          letterSpacing='-1.5px'
          pt='55px'
          pb='35px'>
          The door to your first job in the tech industry
        </Text>
      </Box>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <Box className='nexton-form'>
          <Text
            color='nextonDarkGrey'
            my='100px'
            fontSize={['36px', '40px']}
            lineHeight='1.3'
            letterSpacing='-1.5px'
            textAlign='center'>
            Thanks for filling out the application form! We will contact you soon at your email.{' '}
          </Text>
        </Box>
      </Box>
      <FormBubbles />
    </Flex>
  </Box>
);

export default AcademyThankYouHero;
